<template>
<CHeader position="sticky" class="p-0 mb-4 bg-light text-dark">
    <CContainer fluid class="p-3 mb-2">
        <CHeaderToggler class="bg-black text-white" @click="$store.commit('toggleSidebar')">
            <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>

        <CHeaderBrand class=" d-lg-none" style="margin-left:10px;">
            <img src="@/assets/images/logo-rounded.png" class="" width="40 " height="40" />
        </CHeaderBrand>
        <CHeaderNav class="mt-3" style="margin-left: 12px;">
            <AppHeaderDropdownAccnt />
            <CNavItem  role="button" class="fw-bold text-black" @click.prevent="logOut()" style="text-decoration: none; margin-top:7px;">
                <span class="mx-1">Log out</span>  
                  <CIcon size='lg' icon="cil-lock-locked" /> 
            </CNavItem>
        </CHeaderNav>
    </CContainer>
</CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
export default {
    name: 'AppHeader',
    components: {
        AppHeaderDropdownAccnt,
    },
    methods: {
        logOut() {
            localStorage.removeItem('admin_id')
            this.$router.push({
                name: 'Login'
            })
        },
    },
}
</script>
