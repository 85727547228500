import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    redirect: '/admin/login',
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
    render() {
      return h(resolveComponent('router-view'))
    },
  },
  {
    path: '/admin',
    name: 'DefaultLayout',
    component: DefaultLayout,
    redirect: '/admin/users',
    children: [
      {
        path: '/admin/users',
        name: 'User',
        component: () => import('@/views/user/userDetails.vue'),
      },
      {
        path: '/admin/places',
        name: 'Places',
        component: () => import('@/views/places/placesDetail.vue'),
      },
      {
        path: '/admin/chats',
        name: 'Chats',
        component: () => import('@/views/chat/chatLog.vue'),
      },
      {
        path: '/admin/checked-in-users',
        name: 'Checked In Users',
        component: () => import('@/views/CheckedInUser/checkedInUsers.vue'),
      },
      {
        path: '/admin/checked-in-history',
        name: 'Checked In History',
        component: () => import('@/views/CheckedInUsersHistory/checkedInUsersHistory.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
