<template>
<CDropdown>
    <div class="row">
        <div class="col-lg-8 d-flex px-4" v-for="item in adminDetail" v-bind:key="item.admin_id">
            <CDropdownToggle class="fw-bold text-black" style="border: none; !important">{{item.name}}</CDropdownToggle>
            <CAvatar v-if="item.profile_image_url" class="text-center"><img :src='item.profile_image_url' class="rounded-circle" width="40 " height="40" /></CAvatar>
            <CAvatar v-else class="text-center"><img src='@/assets/images/placeHolderImage.png' class="rounded-circle" width="40 " height="40" /> </CAvatar>
        </div>
    </div>
    <CDropdownMenu>
        <CDropdownItem type="button" role="button" color="primary" @click="() => { lgDemo = true }" class="btn btn dropdown-item p-0 text-center fw-bold">
            <CIcon icon="cil-user" size="s" style="color: black;" />&nbsp;Profile
        </CDropdownItem>
    </CDropdownMenu>
</CDropdown>
<CModal size="s" backdrop="static" :visible="lgDemo" @close="() => { lgDemo = false }">
    <viewAdminProfile :adminDetail="adminDetail" />
</CModal>
</template>

<script>
import axios from 'axios';
import viewAdminProfile from '@/views/admin/viewAdminProfile.vue';
export default {
    name: 'AppHeaderDropdownAccnt',
    components: {
        viewAdminProfile,
    },
    data() {
        return {
            lgDemo: false,
            adminDetail: []
        }
    },
    async mounted() {
        const Headers = {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": JSON.parse(localStorage.getItem('admin_id'))
        }
        let view = await axios.post("admin-profile/" + Headers.Authorization, {}, {
            headers: Headers
        });
        this.adminDetail = view.data.data;
    }
}
</script>
