<template>
<CFooter>
    <div class="mx-auto">
        <strong class="me-1">Copyright</strong>Flok 22 &copy;
        <span class="ms-1">{{ new Date().getFullYear() }}</span>
        <span class="ms-4" target="_blank">Powered By</span>
        <a class="ms-1" href="https://www.agicent.com/">Agicent Technologies PVT LTD</a>
    </div>
</CFooter>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>
