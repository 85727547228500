<template>
<CModalHeader class="bg-black">
    <CModalTitle class="text-white">Reset Password</CModalTitle>
</CModalHeader>
<CModalBody style="background-color: #eee;">
    <CForm class="mb-1">
        <CFormLabel for="inputPassword" class="col-form-label fw-bold">New Password<span style="color: #FF0000;">*</span></CFormLabel>
        <CFormInput type="password" id="password" name="password" placeholder="new password" v-model="password" required />
        <CFormLabel for="inputPassword" class="col-form-label fw-bold"> Confirm New Password<span style="color: #FF0000;">*</span></CFormLabel>
        <CFormInput type="password" id="confirmPassword" name="confirmPassword" placeholder="confirm new Password" v-model="confirmPassword" required />

    </CForm>
    <CRow>
        <div v-if="message =='Password not matched'" class=" text-center" style="color: red; margin-left: 17px;">{{ message }}</div>
        <div v-else-if="message =='Password not reset'" class=" text-center" style="color: red; margin-left: 17px;">{{ message }}</div>
        <div v-else class="text-center" style="color: green; margin-left: 17px;">{{ message }}</div>
    </CRow>
</CModalBody>
<CModalFooter style="justify-content: space-around; !importent">
    <CButton class="bg-black" color="dark" v-on:click="resetPassword()">Update Password</CButton>
</CModalFooter>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
    name: 'deleteUser',
    props: {
        id: String,
    },
    data() {
        return {
            viewDetail: [],
            resetPasswordModalLabel: false,
            message: '',
            password: '',
            confirmPassword: ''
        }
    },
    methods: {
        async resetPassword() {
            if (this.password != this.confirmPassword) {
                this.message = "Password not matched"
            } else {
                const Headers = {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": JSON.parse(localStorage.getItem('admin_id'))
                }
                const data = {
                    password: this.password
                }
                let response = await axios.post("reset-password", data, {
                    headers: Headers
                });
                console.log(response);
                if (response.data.status == '1') {
                    // this.message = response.data.message;
                    swal({
                        title: 'success',
                        text: response.data.message,
                        icon: 'success',
                        buttons: false,
                        timer: 2000,
                    })
                    setTimeout(() => {
                        this.$router.go();
                    }, 2000);
                } else {
                    swal({
                        title: 'Failed',
                        text: response.data.message,
                        icon: 'error',
                        buttons: false,
                        timer: 2000,
                    })
                }

            }

        }
    }
}
</script>
