<template>
<CSidebar class="p-3 mb-2 bg-black text-white" style="width: 18rem;" position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    ">
    <CSidebarBrand class="p-3 mb-2 bg-black text-white">
        <router-link to="/">
            <img src="@/assets/images/logo-rounded.png" class="" width="80 " height="70" />
        </router-link>
    </CSidebarBrand>
    <AppSidebarNav />
</CSidebar>
</template>

<script>
import {
    computed
} from 'vue'
import {
    useStore
} from 'vuex'
import {
    AppSidebarNav
} from './AppSidebarNav'
export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    setup() {
        const store = useStore()
        return {
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
        }
    },
}
</script>
