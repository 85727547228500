export default [
  {
    component: 'CNavItem',
    name: 'User',
    icon: 'cilUser',
    to: '/admin/users',
  },
  {
    component: 'CNavItem',
    name: 'Places',
    icon: 'cilLocationPin',
    to: '/admin/places',
  },
  {
    component: 'CNavItem',
    name: 'Chats',
    icon: 'cilChatBubble',
    to: '/admin/chats',
  },
  {
    component: 'CNavItem',
    name: 'Checked In Users',
    icon: 'cilCheckCircle',
    to: '/admin/checked-in-users',
  },
  {
    component: 'CNavItem',
    name: 'Checked In History',
    icon: 'cilHistory',
    to: '/admin/checked-in-history',
  }
]
