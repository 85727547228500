<template>
<CModalHeader class="bg-black">
    <CModalTitle class="text-white">User Profile</CModalTitle>
</CModalHeader>
<CModalBody>
    <section style="background-color: #eee;">
        <div class="container py-1">
            <div class="row">
                <div class="col">
                    <div class="card-body  text-center mb-2">
                        <div v-if="this.adminDetail[0].profile_image_url" class="p-3"><img :src='this.adminDetail[0].profile_image_url' alt="avatar" class="rounded-circle img-fluid" style="width: 150px; height:150px;" /></div>
                        <div v-else class="p-3"><img src='@/assets/images/placeHolderImage.png' class="rounded-circle img-fluid" style="width: 150px; height:150px;" /> </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-4">
                                    <p class="mb-0 fw-bold">Full Name<span style="color: #FF0000;">*</span></p>
                                </div>
                                <div class="col-sm-8">
                                    <p class="text-muted mb-0 fw-bold">{{this.adminDetail[0].name}}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-4">
                                    <p class="mb-0 fw-bold">Email<span style="color: #FF0000;">*</span></p>
                                </div>
                                <div class="col-sm-8">
                                    <p class="text-muted mb-0 fw-bold">{{this.adminDetail[0].email_id}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mb-3 text-center">
                        <CButton style="padding: 0; border: none; background: none; color: blue; text-decoration: underline;" @click="() => { resetPasswordModalLabel = true }">Reset Password</CButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</CModalBody>
<!--Modal  popup -->
<CModal size='sm' backdrop="static" alignment="center" :visible="resetPasswordModalLabel" @close="() => { resetPasswordModalLabel = false }">
    <resetPassword />
</CModal>
</template>

<script>
import resetPassword from '../admin/resetPassword.vue'
export default {
    name: 'viewAdminProfile',
    props: ['adminDetail'],
    components: {
        resetPassword
    },
    data() {
        return {
            resetPasswordModalLabel: false,
        }
    },
}
</script>
